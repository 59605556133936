import moment from 'moment';
import { mapGetters } from 'vuex';
import systemInfo from '@/api/SystemInfo';


export default {
  computed: {
    ...mapGetters('identity', ['userTimeZone', 'userOriginalTimeZone']),
  },
  data() {
    return {
      datePickerOptions: {
        firstDayOfWeek: 1
      }
    };
  },
  methods: {
    async getNow() {
      // return moment().toDate();
      const timeRes = await systemInfo.getUtcNow();
      const utcNow = timeRes.data;
      // return moment(utcNow).add(this.userTimeZone, 'h').toDate();
      const browserTimezone = -new Date().getTimezoneOffset() / 60;
      const tzOffsetH = browserTimezone - this.userTimeZone;

      return moment(utcNow)
        .add(-tzOffsetH, 'h')
        .toDate();
    },
    dateGetter(date) {
      if (!date) return null;
      return date ? moment(date)
        .add(this.userTimeZone, 'h')
        .toDate() : null;
    },
    dateSetter(date) {
      if (!date) return null;

      const mom = moment(date)
        .add(-this.userTimeZone, 'h');
      const result = mom.format('YYYY-MM-DDTHH:mm:ss.SSS');
      return result;
    },
    toUserDate(date, format = 'YYYY-MM-DDTHH:mm:ss.SSS') {
      if (!date) return null;
      return moment(date)
        .add(this.userTimeZone, 'h')
        .format(format);
    },
    toServerUtcDate(date, format = 'YYYY-MM-DDTHH:mm:ss.SSS') {
      if (!date) return null;
      const strDate = moment(date)
        .add(-this.userTimeZone, 'h')
        .format(format);
      return `${strDate}Z`;
    },
    /**
     * реобразование даты в пользовательское время с учетом смещения
     * @param date
     * @param format
     * @returns {string|null}
     */
    toUserDateV2(date, format = 'YYYY-MM-DDTHH:mm:ss.SSS') {
      if (!date) return null;
      let tz = this.userTimeZone;
      if (!tz) {
        tz = new Date().getTimezoneOffset() / 60;
      }
      return moment(date)
        .utc()
        .add(tz, 'h')
        .format(format);
    },
  }
};
