<template>
  <div class="my-flex-container-column">
    <entity-journal-filter :filter-model="filterModel"  @clear-filters="clearFilters" />
    <div class="my-flex-container-column">
      <pagination
        class="text-center"
        :total-count="totalCount"
        :page-size.sync="pageSize"
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-table style="width: 100%;"
                v-loading="loading"
                :data="items"
                :height="tableHeight"
                :default-sort = "{prop: 'logged', order: 'descending'}"
                @sort-change="handleSortChange">
        <el-table-column prop="logged"
                        label="Дата"
                        sortable="custom"
                        min-width="10"
                        :formatter="dateTimeRenderer" />
        <el-table-column prop="userName"
                        label="Логин"
                        sortable="custom"
                        min-width="10" />
        <el-table-column prop="entityActionTypeName"
                        label="Тип события"
                        sortable="custom"
                        min-width="15" />
        <el-table-column prop="entityTypeName"
                        label="Тип объекта"
                        sortable="custom"
                        min-width="15" />
        <el-table-column prop="entity"
                        label="Объект"
                        min-width="40">
          <template slot-scope="scope">
            <div v-if="scope.row.entityActionTypeName !== 'Обновление объекта'" class="entity-view" v-html="entityJsonToHtml(scope.row.entity)" />
            <div v-else class="entity-view" v-html="entityJsonCompareToHtml(scope.row.entity, scope.row.changedEntity)" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import formatters from '@/mixins/formatters';
import tableResize from '@/mixins/tableResize.js';
import pagination from '@/components/Ui/Pagination';
import entityJournalApi from '@/api/administration/entityJournal';
import entityJournalFilter from './EntityJournalFilter.vue';
// import { filter } from 'vue/types/umd';

const diff = require('recursive-diff');

export default {
  name: 'EntityJournal',
  mixins: [formatters, tableResize],
  components: { pagination, entityJournalFilter },
  data() {
    return {
      loading: false,
      items: [],
      pageNumber: 1,
      pageSize: 20,
      totalCount: 0,
      sortField: 'logged',
      sortDesc: true,
      filterModel: {
        loggedDateTo: '',
        loggedDateFrom: '',
        userNameFilter: '',
        entityTypeIdFilter: [],
        entityActionTypeNameFilter: [],
        entityNameFilter: ''
      },
    };
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getEntityJournal();
    this.tableHeight = this.getTableHeight();
  },
  watch: {
    filterModel: {
      handler() {
        this.getEntityJournal();
      },
      deep: true
    },
  },
  methods: {
    async getEntityJournal() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        loggedDateTo: this.filterModel.loggedDateTo,
        loggedDateFrom: this.filterModel.loggedDateFrom,
        userNameFilter: this.filterModel.userNameFilter,
        entityTypeIdFilter: this.filterModel.entityTypeIdFilter,
        entityActionTypeNameFilter: this.filterModel.entityActionTypeNameFilter,
        entityNameFilter: this.filterModel.entityNameFilter
      };
      const res = await entityJournalApi.getEntityJournal(params);
      if (res.data) {
        this.items = res.data.items;
        this.totalCount = res.data.totalCount;
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getEntityJournal();
    },
    async handleCurrentChange() {
      await this.getEntityJournal();
    },
    async handleSortChange(event) {
      this.sortField = event.prop;
      this.sortDesc = event.order === 'descending';
      await this.getEntityJournal();
    },
    entityJsonCompareToHtml(entityJson, changedEntityJson) {
      let result = '';
      const entity = JSON.parse(entityJson);
      const changedEntity = JSON.parse(changedEntityJson);

      const entityDiff = diff.getDiff(entity, changedEntity, true);
      // console.log(entityDiff);

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in changedEntity) {
        const change = entityDiff.find((df) => df.path[0] === prop);
        // console.log(change);

        if (change) {
          result += `
            <div>
              <span class="updated">
                <span class="key">${prop}</span>:
                <span>${entity[prop]}</span> =>
                <span>${change.val}</span>
              </span>
            </div>`;
        } else {
          result += `
            <div>
              <span class="key">${prop}</span>: <span>${entity[prop]}</span>
            </div>`;
        }
      }

      return result;
    },
    entityJsonToHtml(json) {
      let result = '';
      const entity = JSON.parse(json);

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in entity) {
        result += `
          <div>
            <span class="key">${prop}</span>: <span>${entity[prop]}</span>
          </div>`;
      }

      return result;
    },
    clearFilters() {
      this.filterModel = {
        loggedDateTo: '',
        loggedDateFrom: '',
        userNameFilter: '',
        entityTypeIdFilter: [],
        entityActionTypeNameFilter: [],
        entityNameFilter: ''
      };
    }
  }
};
</script>

<style lang="scss">

</style>
